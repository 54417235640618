<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isDarkTheme = computed(() => {
	return store.getters['interfaceSettings/darkTheme']
})
import ModuleHeader from "@/layout/Header/PageHeader.vue"
import SearchByUrlController from '@/components/searchByUrlController/searchByUrlController.vue'
</script>

<template>
	<div class="page-main-wrap" :class="{darkTheme: isDarkTheme === true}">
		<search-by-url-controller/>
		<module-header />
		<router-view />
	</div>
</template>

<style scoped lang="scss">

</style>
