<script setup>
import './fileuploadBox.scss'
import { onMounted, onUnmounted, computed, ref, onUpdated, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const router = useRouter()
const store = useStore()
import FileUpload from 'primevue/fileupload'
const fileUploadComponent = ref()
const lastPageNumber = ref(0)
const resultsFrom = computed(() => {
	return store.getters['search/resultsFrom']
})
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})

onMounted(() => {})

const isFigureSearch = computed(() => {
	return store.getters['interfaceSettings/isFigureSearch']
})
const searchByFigure = computed(() => {
	return store.getters['search/searchByFigure']
})
function onAdvancedUpload(ev) {
	console.log(ev)
}
function handleSelectFileUpload(ev) {
	 if(ev.files[0]){
		 store.commit('search/searchByFigureUpload', true)
		 store.commit('search/uploadedFileToSearch', ev.files[0])
		 store.commit('search/isFigureSearch',true)
		 router.push({ name: 'SearchPage', params: { s: false }, query: { upload: true }})
		 store.dispatch('search/search')
	 }

}
function handlePickFile(){
	fileUploadComponent.value.choose()
}
function formatSize(bytes){
	const k = 1024;
	const dm = 3;
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	if (bytes === 0) {
		return `0 ${sizes[0]}`;
	}

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

	return `${formattedSize} ${sizes[i]}`;
}
</script>

<template>
	<div class="fileupload-modal-box">
		<div class="fileupload-holder">
			<button class="bg-close-btn" type="button" @click="store.commit('search/searchByFigureModalOpen', false)"></button>
			<div class="fileupload-frame">
				<button class="close-btn"  type="button" @click="store.commit('search/searchByFigureModalOpen', false)">
					<span></span>
				</button>
				<div class="modal-heading-box">
					<span class="modal-title">Search by image</span>
					<span class="modal-subtitle">Find similar images in the library</span>
				</div>
				<div class="fileupload-form-box">
					<FileUpload
						ref="fileUploadComponent"
						name="demo[]"
						@select="handleSelectFileUpload"
						:multiple="false"
						accept="image/*"
						:maxFileSize="1000000"
						:fileLimit="1"

					>
						<template #header>
							<span></span>
						</template>
						<template #empty>
							<div class="drop-info">
								<button type="button" class="abs-btn" @click="handlePickFile">Browse files</button>
								<div class="drop-icon-box">
									<img src="@/assets/images/vector/drop-files-icon.svg" alt="">
									<span class="drop-title">Drag and drop your file here</span>
								</div>
								<span class="or">or</span>
								<button type="button" @click="handlePickFile" class="fake-btn">Browse files</button>
								<span class="req">JPG, PNG  •  1 MB max</span>
							</div>
						</template>
						<template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
							<div class="searched-box" v-if="files.length > 0">
								<div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="searched-have">
									<div class="searched-image">
										<img class="uploadedImage" role="presentation" :alt="file.name" :src="file.objectURL" />
										<div class="searched-abs-info">
											<span class="image-name">{{ file.name }}</span>
											<span class="image-size">{{ formatSize(file.size) }}</span>
										</div>
										<div class="loader-pic">
											<img src="@/assets/images/vector/curve-spinner.svg" alt="">
										</div>
									</div>
								</div>
							</div>
						</template>
					</FileUpload>
				</div>
			</div>
		</div>
	</div>
</template>
