<script setup>
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const emitter = inject('emitter')
const props = defineProps({
	index: {
		type: Number,
		required: true,
		default: 0
	},
	figureData: {
		type: Object,
		required: true
	}
})
const emit = defineEmits(['openGallery'])
const isFigureLoaded = ref(false)
const selectedIds = computed(() => {
	return store.getters['search/selectedIds']
})

onMounted(() => {

})

onUnmounted(() => {})

function short(string, maxChars) {
	//console.log(string)
	if (string) {
		if (string.length > maxChars) {
			string = string.substring(0, maxChars) + '...'
			string = string.replace(/,\s*$/, '')
		}
	}
	return string
}

function handleLoadedImage(element){

	isFigureLoaded.value = true
}
</script>
<template>
	<div class="figure-item" :class="{ selected: selectedIds.includes(props.index) === true }">
		<div class="item-holder">
			<div class="image-box">
				<div class="pic-wrap">
					<div class="pic" :class="{ loaded: isFigureLoaded === true }">
						<img @load="handleLoadedImage()" :src="props.figureData.data.figureurl" />
						<div class="_g-lazy-block"></div>
						<button class="open-gallery-btn" @click="emit('openGallery', props.index)">
							<img src="@/assets/images/vector/zoom-icon.svg" alt="" />
						</button>
					</div>

				</div>
				<div class="props-line">
					<div class="main-info">
						<span class="info-item"><b>{{ props.figureData.data.Year }}</b></span>
						<span class="info-item journal">{{props.figureData.data.journaltitle}}</span>
					</div>
					<div class="right-buttons">
						<div class="prop lock-status"><img src="/assets/images/vector/lock.svg" alt="" /></div>
						<a
							:href="'https://pubmed.ncbi.nlm.nih.gov/' + props.figureData.data.pmid"
							class="prop link"
							target="_blank"
						>
							<i><img src="/assets/images/vector/icon-link.svg" alt="" /></i>
						</a>
						<a :href="props.figureData.data.figureurl" class="prop download">
							<i><img src="@/assets/images/vector/export-btn.svg" alt="" /></i>
						</a>

					</div>
				</div>
			</div>
			<div class="item-text">
				<p>{{ short(props.figureData.data.figurecaption, 160) }}</p>
			</div>
		</div>
	</div>
</template>
