<script setup>
import './pagination.scss'
import { onMounted, onUnmounted, computed, ref, onUpdated, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Paginator from 'primevue/paginator';

const router = useRouter()
const lastPageNumber = ref(0)
const store = useStore()
const resultsPerPage = computed(() => {
	return store.getters['search/resultsPerPage']
})
const first = ref(1)
const desiredPages = ref(8)
const realPages = ref(0)
const pagesFrom = ref(0)
const totalPages = computed(() => {
	return store.getters['search/totalPages']
})
const totalResults = computed(() => {
	return store.getters['search/totalResults']
})

const currentPage = computed(() => {
	return store.getters['search/currentResultPage']
})
const resultsFrom = computed(() => {
	return store.getters['search/resultsFrom']
})
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
watch(
	() => totalPages.value,
	(total) => {
		first.value = 1
		realPages.value = 8
		if (totalPages.value < desiredPages.value) {
			realPages.value = totalPages.value
		}
	},
	{ immediate: false }
)
watch(
	() => currentPage.value,
	(currentPageNumber) => {

		console.log(realPages.value)
		if (currentPageNumber > realPages.value) {
			pagesFrom.value = currentPageNumber - realPages.value
			console.log(pagesFrom.value)
		}
		if (pagesFrom.value === currentPage.value - 1) {
			pagesFrom.value = 0
		}
		if (pagesFrom.value >= 100) {
			pagesFrom.value = 0
		}
	},
	{ immediate: false }
)
onMounted(() => {})
onUnmounted(() => {})

function handleNextPageBtn() {
	window.scrollTo(0, 0)
	store.commit('search/goNextPage')

	store.dispatch('search/search')
}

function handlePrevPageBtn() {
	window.scrollTo(0, 0)
	store.commit('search/goPrevPage')
	store.dispatch('search/search')
}


function handlePageChange(ev){
	console.log(ev)
	window.scrollTo(0, 0)
	store.commit('search/goPageNumber', ev.page+1)
	store.dispatch('search/search')

}

function handleLastPageBtn() {
	window.scrollTo(0, 0)
	store.commit('search/goLastPage')
	store.dispatch('search/search')
}
</script>

<template>
	<div class="pagination-wrapper">
		<Paginator  v-model:first="first" :rows="resultsPerPage" :totalRecords="totalResults" @page="handlePageChange" currentPageReportTemplate="{currentPage} - {totalPages}" template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" />



	</div>
</template>
