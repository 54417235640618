import { createStore } from 'vuex'
import interfaceSettings from '@/store/InterfaceSettings.js'
import search from '@/store/Search.js'

const store = createStore({
	modules: {
		interfaceSettings,
		search
	}
})
// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('interfaceSettings', JSON.stringify(state.interfaceSettings))
})

export default store
