<script setup>
import './pricing-window.scss'
import { onMounted, onUnmounted, computed, onUpdated, inject, watch } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
const store = useStore()

onMounted(() => {})

function handleModalClose() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
}

function handleOneMonthBtn() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
}
function handleOneYearBtn() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
}
</script>

<template>
	<modal-box class-name="pricing-modal" @close="handleModalClose">
		<div class="modal-heading">
			<span class="modal-window-title">Arcella Search Pricing</span>
		</div>
		<div class="pricing-table">
			<div class="pricing-item">
				<div class="item-holder">
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">1-month plan</span>
 						</div>
						<div class="price-amount-box">
							<span class="limited-price">Introductory price</span>
							<div class="price"><b>€6.99</b><span>/month</span></div>
						</div>
						<div class="get-wrapper">
							<button @click="handleOneMonthBtn" class="get-btn" type="button"><span>Get 1-month plan</span></button>
						</div>
						<span class="billed">Billed at €6.99 every month</span>
					</div>
				</div>
			</div>
			<div class="pricing-item featured">
				<div class="item-holder">
					<div class="featured-label">BEST DEAL</div>
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">1-year plan</span>
						</div>
						<div class="price-amount-box">
							<span class="limited-price">Introductory price</span>
							<div class="price"><b>€4.99</b><span>/month</span></div>
						</div>
						<div class="get-wrapper">
							<button @click="handleOneYearBtn" class="get-btn" type="button"><span>Get 1-year plan</span></button>
						</div>
						<span class="billed">Billed at  €59.99 for 1 year</span>
					</div>


				</div>
			</div>
			<div class="pricing-item enterprice">
				<div class="item-holder">
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="enterprice-title">Enterprise</span>
						</div>
						<div class="price-amount-box">
							<div class="price">Please contact us <br>for a quote</div>

						</div>
						<div class="get-wrapper">
							<button class="get-btn" type="button"><span>Contact us</span></button>
						</div>
						<span class="billed">For Vendors, BioPharma and Academia</span>
					</div>

				</div>
			</div>
		</div>
		<div class="pricing-info-bottom-row">
			<div class="what-get-box">
				<span class="get-title"><b>Subscription includes:</b></span>
				<ul class="abilites-list">
					<li>Unlimited search</li>
					<li>Unlimited export XLS, TSV, CSV</li>

					<li>Keyword search</li>
					<li>Image similarity search</li>
				</ul>
			</div>
			<div class="advanced-plan-box">
				<div class="adv-plan">
					<span class="badge">Coming soon</span>
					<span class="plan-name">Advanced search</span>
					<span class="what-do">Powered by 40+ ontologies and visualization tool to enhance your data exploration</span>
				</div>


			</div>
		</div>

	</modal-box>
</template>
