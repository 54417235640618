<script setup>
import { inject, computed, reactive, ref, onMounted, nextTick } from 'vue'
import './Header.scss'
import HeaderSearch from '@/components/header-search/header-search.vue'
import { useStore } from 'vuex'
import router from '@/router.js'
import UserNavigation from '@/components/user-navigation/user-navigation.vue'
const store = useStore()
const emitter = inject('emitter')



const isDarkTheme = computed(() => {
	return store.getters['interfaceSettings/darkTheme']
})
const userFontSize = computed(() => {
	return store.getters['interfaceSettings/fontSize']
})
const isUserLogged = computed(() => {
	return store.getters['interfaceSettings/isUserLogged']
})
const userMenuOpened = computed(() => {
	return store.getters['interfaceSettings/userMenuOpened']
})
const userLoginInProgress = computed(() => {
	return store.getters['interfaceSettings/userLoginInProgress']
})
const user = computed(() => {
	return store.getters['interfaceSettings/userData']
})
function userFontIncrement() {
	store.commit('interfaceSettings/fontSizePlus', !isDarkTheme.value)
}
function userFontDecrement() {
	store.commit('interfaceSettings/fontSizeMinus', !isDarkTheme.value)
}
function themeSwitchHandler(){
	store.commit('interfaceSettings/darkTheme', !isDarkTheme.value)
}
function handleUserSettingsDropboxMouseLeave(){
	store.commit('interfaceSettings/userMenuOpened', false)
}


function handlePricingWindowShowBtn(){
	store.commit('interfaceSettings/pricingWindowShow', true)
}
function handleUserMenuBtn() {
	store.commit('interfaceSettings/userMenuOpened', !userMenuOpened.value)
}
</script>
<template>
	<header class="site-header">
		<div class="header-container">
			<div class="header-row">
				<div class="logo-col">
					<a href="" class="logo" @click="router.push({ name: '/'})">
						<img src="@/assets/images/logo-new.png" alt="">
					</a>
				</div>
				<div class="search-col">
					<header-search/>
				</div>
				<div class="user-col">
					<div class="theme-switcher">
						<input type="checkbox" id="darkmode-toggle" :checked="isDarkTheme === true" @change="themeSwitchHandler"/>
						<label for="darkmode-toggle">
							<span class="light"><img src="@/assets/images/vector/sun.svg" alt="" class="sun" /></span>
							<span class="dark"><img src="@/assets/images/vector/moon.svg" alt="" class="moon" /></span>
						</label>
					</div>
					<div class="font-size-changer" :class="'fontSize-' + userFontSize">
						<button class="change-minus" type="button" @click="userFontDecrement">A</button>
						<div class="indicator"><span></span></div>
						<button class="change-plus" type="button" @click="userFontIncrement">A</button>
					</div>
					<template v-if="isUserLogged === true">
						<user-navigation />
					</template>

				</div>
			</div>
		</div>
	</header>
</template>
