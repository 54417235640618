<script setup>
import './login-window.scss'
import { computed, onMounted } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
import { googleTokenLogin } from 'vue3-google-login'
import { api } from '@/arcella-api/api.js'

const store = useStore()

const isUserLogged = computed(() => {
	return store.getters['interfaceSettings/isUserLogged']
})
const userLoginInProgress = computed(() => {
	return store.getters['interfaceSettings/userLoginInProgress']
})
const user = computed(() => {
	return store.getters['interfaceSettings/userData']
})

const login = () => {
	googleTokenLogin().then((response) => {
		console.log('Handle the response', response)
		store.commit('interfaceSettings/userLoginInProgress', true)
		const recievedUserInfo = async (token) => {
			const googleAccountResponse = await api.userservices.googleAccInfo(response.access_token)
			const arcellaResponse = await api.userservices.googleSignUp(response.access_token)
			return {
				apikey: arcellaResponse.data.apikey,
				email: arcellaResponse.data.email,
				emailVerified: arcellaResponse.data.emailVerified,
				userId: arcellaResponse.data.userId,
				pictureUrl: googleAccountResponse.data.picture,
				familyName: googleAccountResponse.data.family_name,
				givenName: googleAccountResponse.data.given_name,
				name: googleAccountResponse.data.name
			}
		}
		recievedUserInfo(response.access_token)
			.then((userData) => {
				console.log(userData)
				store.commit('interfaceSettings/setUser', userData)
				store.commit('interfaceSettings/userLoginInProgress', false)
				store.commit('interfaceSettings/loginModalOpen', false)



			})
			.catch((e) => {
				console.log('There has been problem: ' + e.message)
			})

		console.log(recievedUserInfo())

		// api.userservices.googleSignUp(response.access_token).then((responseFromAPI) => {
		// 	const userDataFromArcella = responseFromAPI.data
		// 	recievedUserInfo.apiKey = userDataFromArcella.apikey
		// 	recievedUserInfo.userid = userDataFromArcella.userId
		// 	if (userData.emailVerified === true) {
		// 		store.commit('interfaceSettings/setUser', userDataFromArcella)
		// 		store.commit('interfaceSettings/userLoginInProgress', false)
		// 	}
		// 	console.log(recievedUserInfo)
		// })
	})
}
onMounted(() => {})

function handleModalClose() {
	store.commit('interfaceSettings/loginModalOpen', false)
}
</script>

<template>
	<modal-box class-name="login-modal" @close="handleModalClose" >
		<div class="loader-pic" :class="{ loading: userLoginInProgress === true}">
			<img src="@/assets/images/vector/curve-spinner.svg" alt="">
		</div>
		<div class="login-heading">
			<span class="login-window-title">Login to Arcella</span>
		</div>
		<div class="login-buttons">
			<div class="item">
				<button class="login-btn" type="button" @click="login">
					<i><img src="@/assets/images/vector/google-btn.svg" alt="" /></i>
					<span>Continue with Google</span>
				</button>
			</div>
			<div class="item">
				<button class="login-btn btn-apple" type="button">
					<i><img src="@/assets/images/vector/apple-btn.svg" alt="" /></i>
					<span>Continue with Apple</span>
				</button>
			</div>
		</div>
		<div class="warn-box">
			<p>
				By Signing up, you agree to the
				<a target="_blank" href="/assets/pdf/COOKIE_POLICY_ARCELLA(2024).pdf">Terms of Service</a> and
				<a target="_blank" href="./assets/pdf/PRIVACY_POLICY_ARCELLA.pdf">Privacy Policy</a>, including
				<a target="_blank" href="./assets/pdf/COOKIE_POLICY_ARCELLA(2024).pdf">Cookies</a>
			</p>
		</div>
	</modal-box>
</template>
