<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import './SearchPage.scss'
import Sidebar from '@/layout/Sidebar/Sidebar.vue'
import TextResults from '@/views/Search/TextResults/TextResults.vue'
import FiguresResults from '@/views/Search/FiguresResults/FiguresResults.vue'
import { useRouter } from 'vue-router'
import PhotoswipeGallery from '@/components/photoswipe-gallery/photoswipe-gallery.vue'
import Pagination from '@/components/pagination/pagination.vue'
import ArcGallery from '@/components/arc-gallery/arc-gallery.vue'


const router = useRouter()
const store = useStore()
const isMobile = inject('isMobileCheck')

const isFigureSearch = computed(() => {
	return store.getters['search/isFigureSearch']
})
const searchText = computed(() => {
	return store.getters['search/searchText']
})
const resultsPerPage = computed(() => {
	return store.getters['search/resultsPerPage']
})
const foundedResultsCount = computed(() => {
	return store.getters['search/totalResults']
})
const resultsFrom = computed(() => {
	return store.getters['search/resultsFrom']
})

const isNothingFound = computed(() => {
	return store.getters['search/isNothingFound']
})
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const galleryOpen = computed(() => {
	return store.getters['search/galleryOpen']
})
const arcGalleryOpen = computed(() => {
	return store.getters['search/arcGalleryOpen']
})

onMounted(() => {
	// store.commit('search/searchText', searchText.value)
	// store.commit('search/wantResetPagination', true)
	// store.commit('search/wantUpdateHistogram', true)
	// store.dispatch('search/startSearch', false)
})

onUnmounted(() => {
	console.log(`unmount searchPage`)
})
</script>
<template>
	<div class="search-page">
		<sidebar />
		<div class="searchpage-main-row">
			<section class="search-page-main">
				<div v-if="isNothingFound === true && isLoading === false" class="notFound">Nothing found..</div>
				<template v-else>
					<div class="search-top-heading-row">
						<div class="left-col">
							<span class="founded-stat">
								<b>{{ resultsFrom }} - {{ resultsFrom + resultsPerPage }}</b> out of
								<b>{{ foundedResultsCount }}</b> results
							</span>
						</div>
						<div class="right-col">
							<pagination />
						</div>
					</div>

					<template v-if="isFigureSearch">
						<figures-results />
					</template>
					<template v-else>
						<text-results />
					</template>
				</template>
			</section>
		</div>

		<photoswipe-gallery v-if="galleryOpen != null" id="gala" :openIndex="galleryOpen" />
		<Transition name="fade">
			<arc-gallery :open-index="arcGalleryOpen" v-if="arcGalleryOpen != null" />
		</Transition>
	</div>
</template>
