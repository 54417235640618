<script setup>
import './search-table.scss'

import { onMounted, onUnmounted, computed, ref, inject, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import TableRow from '@/components/search-table/table-row/table-row.vue'
const store = useStore()
import { downloadCSV } from '@/helpers/exportCSV.js'
import { downloadTSV } from '@/helpers/exportTSV.js'
import { downloadXLSX } from '@/helpers/exportXLSX.js'
const tableWrapperElem = ref()
const scrollerHeadElem = ref()
const scrollerMainElem = ref()
const scrolledPX = ref(0)
const tableOffsetTop = ref()
const isChildExpanded = ref(false)
const isLoadedAdditionColumns = computed(() => {
	return store.getters['search/isAllAdditionColumnsLoaded']
})
const isFilterDropOpen = ref(false)
const isAddColumnDropOpen = ref(false)
const isExportDropOpen = ref(false)
const isSidebarHidden = computed(() => {
	return store.getters['interfaceSettings/hiddenSidebar']
})
watch(
	() => isSidebarHidden.value,
	(isHidden) => {
		setTimeout(() => {
			store.commit('interfaceSettings/setTableWidth', tableWrapperElem.value.getBoundingClientRect().width)
		}, 400)
	},
	{ immediate: false }
)
const searchResultColumns = computed(() => {
	return store.getters['search/resultsData']
})
const searchText = computed(() => {
	return store.getters['search/searchText']
})
const currentResultPage = computed(() => {
	return store.getters['search/currentResultPage']
})
const tableColumns = computed(() => {
	return store.getters['search/allColumns']
})
const tableWidth = computed(() => {
	return store.getters['interfaceSettings/tableWidth']
})
const selectedIds = computed(() => {
	return store.getters['search/selectedIds']
})
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const userFontSize = computed(() => {
	return store.getters['interfaceSettings/fontSize']
})
const availableSortFields = computed(() => {
	return store.getters['search/availableSortFields']
})
const selectedSortField = computed(() => {
	return store.getters['search/selectedSortField']
})
onBeforeMount(() => {
	window.addEventListener('scroll', handleWindowScroll)
	window.addEventListener('resize', handleWindowResize)
})
onMounted(() => {
	console.log(`mount table`)
	handleWindowResize()
})
onUnmounted(() => {
	window.removeEventListener('scroll', handleWindowScroll)
})

watch(
	() => isLoading.value,
	(loadingState) => {
		if (loadingState === false) {
			handleWindowResize()
		}
	},
	{ immediate: false }
)

//замок закрытый вместо красного малиновый

//сделать подсказки к блоку внизу футер

function handleWindowScroll() {
	if (window.scrollY >= tableOffsetTop.value) {
		tableWrapperElem.value.classList.add('fixed-thead')
	} else {
		tableWrapperElem.value.classList.remove('fixed-thead')
	}
}
function handleWindowResize() {
	tableOffsetTop.value = tableWrapperElem.value.getBoundingClientRect().top + window.scrollY
	//console.log(tableWrapperElem.value.getBoundingClientRect().width)
	store.commit('interfaceSettings/setTableWidth', tableWrapperElem.value.getBoundingClientRect().width)
}
function handleScrollHeadElem(el) {
	scrollerMainElem.value.scrollLeft = el.target.scrollLeft
	scrolledPX.value = el.target.scrollLeft
}
function handleScrollMainElem(el) {
	scrollerHeadElem.value.scrollLeft = el.target.scrollLeft
	scrolledPX.value = el.target.scrollLeft
}

function addRemoveColumn(columnID) {
	const nextActiveStatus = tableColumns.value.find(({ id }) => id === columnID).active
	if (nextActiveStatus) store.commit('search/removeColumn', columnID)
	else store.dispatch('search/addColumn', columnID)
}

function handleCheckBoxItemChange(id) {
	if (selectedIds.value.includes(id) === false) {
		store.commit('search/addSelectedId', id)
	} else {
		store.commit('search/removeSelectedId', id)
	}
}
function handleRemoveColumn(id) {
	store.commit('search/removeColumn', id)
}
function selectedSentenceHeaderCheckbox(checkbox) {
	console.log(selectedIds.value.length)
	console.log(searchResultColumns.value.length)
	if (selectedIds.value.length === searchResultColumns.value.length) {
		selectedIds.value.splice(0)
	} else {
		selectedIds.value.splice(0)
		for (let i = 1; i <= searchResultColumns.value.length; i++) {
			selectedIds.value.push(i)
		}
	}
	console.log(selectedIds.value)
}
function handleClickResetSorting(event) {
	if (selectedSortField.value.query) {
		store.commit('search/resetSortField')
		store.dispatch('search/search')
	}
}
function handleClickSortBtn(event, selectedField) {
	store.commit('search/updateSortField', selectedField)
	store.dispatch('search/search')
}
function handleClickSortOutside(event) {
	isFilterDropOpen.value = false
}
function handleClickDropColumnsOutside(event) {
	isAddColumnDropOpen.value = false
}

function handleClickExportOutside(event) {
	isExportDropOpen.value = false
}
function getSelectedColumnsArray(){
	const arrayToExport = []
	selectedIds.value.forEach((id, index) => {
		arrayToExport.push(searchResultColumns.value[id-1])
	});
	return arrayToExport
}
function handleCSVExport() {
	const filename = searchText.value + '- Page ' + currentResultPage.value
	console.log(getSelectedColumnsArray())
	downloadCSV(getSelectedColumnsArray(), tableColumns.value, filename)
}

function handleXLSXExport() {
	const filename = searchText.value + '- Page ' + currentResultPage.value
	downloadXLSX(getSelectedColumnsArray(), tableColumns.value, filename)
}
function handleTSVExport() {
	const filename = searchText.value + '- Page ' + currentResultPage.value
	downloadTSV(getSelectedColumnsArray(), tableColumns.value, filename)
}
</script>

<template>
	<div class="table-buttons-row">
		<div class="left-col">
			<div class="btn-item item-add-columns jsCurrentParent">
				<button
					class="btn"
					type="button"
					:class="{ active: isAddColumnDropOpen === true }"
					@click="isAddColumnDropOpen = !isAddColumnDropOpen"
				>
					<i><img src="@/assets/images/vector/add-columns-btn.svg" alt="" /></i>
					<span>Add columns</span>
				</button>
				<div class="btn-drop drop-columns" v-if="isAddColumnDropOpen" v-move-outside="handleClickDropColumnsOutside">
					<div class="drop-holder">
						<template v-for="(column, i) of tableColumns" :key="i">
							<label class="custom-checkbox">
								<input
									type="checkbox"
									@change="addRemoveColumn(column.id)"
									name="tableColumns"
									:checked="column.active"
									:value="column.id"
								/>
								<i class="ch-btn"></i><span class="checkbox-holder">{{ column.name }}</span>
							</label>
						</template>
					</div>
				</div>
			</div>
			<div class="btn-item item-filter jsCurrentParent">
				<button
					class="btn"
					type="button"
					:class="{ active: isFilterDropOpen === true }"
					@click="isFilterDropOpen = !isFilterDropOpen"
				>
					<i><img src="@/assets/images/vector/filter-btn.svg" alt="" /></i>
					<span
						>Sort By<em v-if="selectedSortField.query">: {{ selectedSortField.name }}</em></span
					>
				</button>
				<div class="btn-drop" v-if="isFilterDropOpen" v-move-outside="handleClickSortOutside">
					<div class="drop-holder">
						<button
							class="drop-btn"
							@click="handleClickResetSorting"
							:class="{ active: !selectedSortField.query }"
							type="button"
						>
							<span>Default</span>
						</button>
						<template v-for="(sortField, i) of availableSortFields" :key="i">
							<button
								@click="handleClickSortBtn($event, sortField)"
								class="drop-btn"
								:class="{ active: sortField.name === selectedSortField.name }"
								type="button"
							>
								<span>{{ sortField.name }}</span>
							</button>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="right-col">
			<div class="btn-item item-export jsCurrentParent">
				<button
					class="btn"
					type="button"
					:disabled="isLoadedAdditionColumns === false || selectedIds.length < 1"
					:class="{ active: isExportDropOpen === true }"
					@click="isExportDropOpen = !isExportDropOpen"
				>
					<i><img src="@/assets/images/vector/export-btn.svg" alt="" /></i>
					<span>Export</span>
				</button>
				<div class="btn-drop drop-export" v-if="isExportDropOpen" v-move-outside="handleClickExportOutside">
					<div class="drop-holder">
						<button @click="handleXLSXExport" class="drop-btn" type="button"><span>XLS</span></button>
						<button @click="handleTSVExport" class="drop-btn" type="button"><span>TSV</span></button>
						<button @click="handleCSVExport" class="drop-btn" type="button"><span>CSV</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="main-table-box b-table-sticky-header table-responsive" ref="tableWrapperElem">
		<div class="head-table-fix">
			<div class="table-holder">
				<div class="table-frame table-wrapper-x" ref="scrollerHeadElem" @scroll="handleScrollHeadElem">
					<table class="heading-table table b-table">
						<thead>
							<tr>
								<th class="sticky-column">
									<div class="title-box">
										<span>Sentence</span>
										<div class="right-side">
											<div
												class="selected-state"
												:class="[{ hundred: selectedIds.length >= 100 }, { zero: selectedIds.length === 0 }]"
											>
												<em>Selected:</em>
												<span>{{ selectedIds.length }}</span>
												<label class="custom-checkbox" v-if="searchResultColumns">
													<input
														type="checkbox"
														@change="selectedSentenceHeaderCheckbox"
														:checked="selectedIds.length === searchResultColumns.length"
													/>
													<i class="ch-btn"></i>
												</label>
												<label class="custom-checkbox" v-else>
													<input type="checkbox" />
													<i class="ch-btn"></i>
												</label>
											</div>
										</div>
									</div>
								</th>
								<template v-for="(column, i) of tableColumns" :key="i">
									<th :class="'cell-' + column.name.toLowerCase()" v-if="column.active === true">
										<div class="title-box">
											<span>{{ column.name }}</span>
											<button class="global-close-btn" type="button" @click="handleRemoveColumn(column.id)"></button>
										</div>
									</th>
								</template>
							</tr>
						</thead>
					</table>
				</div>
			</div>
		</div>
		<div class="table-wrapper-x table-tbody-wrap" ref="scrollerMainElem" @scroll="handleScrollMainElem">
			<table role="table" class="table" :class="'fontSize-' + userFontSize">
				<tbody role="rowgroup">
					<template v-if="isLoading">
						<tr role="row" v-for="(lazyItem, i) of 3" :key="i">
							<th role="rowheader" scope="row" class="sticky-column">
								<div class="sticky-column-box">
									<div class="sentence-content">
										<div class="_g-lazy-block mod-column"></div>
									</div>
									<div class="sticky-column-footer">
										<div class="panel-elements">
											<div class="result-sentnce-selector">
												<div class="loader-checkbox _g-lazy-block"></div>
											</div>
											<div class="sentence-props">
												<div class="prop lock-status _g-lazy-block"></div>
												<div class="prop citations _g-lazy-block"></div>
												<div class="prop participants _g-lazy-block"></div>
												<div class="prop link _g-lazy-block"></div>
											</div>
										</div>
										<div class="more-btn _g-lazy-block"></div>
									</div>
								</div>
							</th>

							<template v-for="(column, i) of tableColumns" :key="i">
								<td
									:aria-colindex="i"
									role="cell"
									:class="'cell-' + column.name.toLowerCase()"
									v-if="column.active === true"
								>
									<div class="_g-lazy-block mod-column"></div>
								</td>
							</template>
						</tr>
					</template>
					<template v-else v-for="(resultItem, i) of searchResultColumns" :key="++i">
						<table-row
							:data="resultItem"
							:index="i"
							:scrolledPX="scrolledPX"
							:tableWidth="tableWidth"
							@checked="handleCheckBoxItemChange(i)"
						/>
					</template>
				</tbody>
				<!---->
			</table>
		</div>
	</div>
</template>
