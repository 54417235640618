<script setup>
import { computed, nextTick, onBeforeMount, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const route = useRoute()
const router = useRouter()
const searchByFigureUpload = computed(() => {
	return store.getters['search/searchByFigureUpload']
})
const figureUploadSearchIDStore = computed(() => {
	return store.getters['search/figureUploadSearchID']
})
onBeforeMount(() => {
	const isFigureRoute = route.query.figure === 'true'
	const searchQuery = route.params.s
	const isByUploadRoute = route.query.upload === 'true'
	const figureUploadSearchID = route.query.id
	if(isByUploadRoute === true){
		console.log(`upload!!`)
		store.commit('search/searchByFigureUpload', true)
		store.commit('search/isFigureSearch', true)
		store.commit('search/figureUploadSearchID', figureUploadSearchID)
		store.dispatch('search/search')
	}
	else{
		console.log(route.params.s)
		store.commit('search/searchText', searchQuery)
		store.commit('search/isFigureSearch', isFigureRoute)
		store.dispatch('search/search')
	}
})
onMounted(() => {

})

watch(
	() => figureUploadSearchIDStore.value,
	(searchID) => {
		if(searchID){
			console.log(searchID)
			router.push({ name: 'SearchPage', params: { s: false },   query: { ...router.currentRoute.value.query, id: searchID }})
		}
	},
	{ immediate: false }
)
</script>

<template>

</template>


