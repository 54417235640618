<script setup>
import './modal-box.scss'
import { onMounted, onUnmounted, computed, inject, watch } from 'vue'

const emit = defineEmits(['close'])

const props = defineProps({
	className: {
		type: String,
		required: false
	},
	frameClass: {
		type: String,
		required: false
	},
})
onMounted(() => {})



</script>

<template>
	<div class="modal-box" :class="props.className">
		<div class="modal-holder">
			<button class="bg-close-btn" type="button" @click="emit('close')"></button>
			<div class="modal-frame" :class="props.frameClass">
				<button class="close-btn"  type="button" @click="emit('close')"><span></span></button>
				<slot></slot>
			</div>
		</div>
	</div>
</template>
