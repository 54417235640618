<script setup>
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import './FiguresResults.scss'
import Pagination from '@/components/pagination/pagination.vue'
import FigureItem from '@/views/Search/FiguresResults/FigureItem.vue'
const store = useStore()

const figuresResultsData = computed(() => {
	return store.getters['search/resultsData']
})

const publicationFigures = ref('')
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const userFontSize = computed(() => {
	return store.getters['interfaceSettings/fontSize']
})
const availableSortFields = computed(() => {
	return store.getters['search/availableSortFields']
})
const selectedSortField = computed(() => {
	return store.getters['search/selectedSortField']
})
const isFilterDropOpen = ref(false)

const galleryImages = computed(() => {
	const galleryItems = []
	if (figuresResultsData.value) {
		figuresResultsData.value.map((item, index) => {
			galleryItems.push({
				id: index,
				src: item.data.figureurl,
				caption: item.data.figurecaption,
				doi:item.data.doi
			})
		})
	}
	return galleryItems
})
onMounted(() => {})

onUnmounted(() => {})

watch(
	() => figuresResultsData.value,
	(figuresData) => {
		publicationFigures.value = figuresData
	},
	{ immediate: false }
)

function handleClickPicture(index) {
	store.commit('search/updateGalleryImages', galleryImages.value)
	store.commit('search/arcGalleryOpen', index)
}
function handleClickResetSorting(event) {
	if (selectedSortField.value.query) {
		store.commit('search/resetSortField')
		store.dispatch('search/search')
	}
}
function handleClickSortBtn(event, fieldname) {
	store.commit('search/updateSortField', fieldname)
	store.dispatch('search/search')
}
function handleClickSortOutside(event) {
	isFilterDropOpen.value = false
}

</script>
<template>
	<div class="figures-heading-filter-row">
		<div class="btn-item item-filter jsCurrentParent">
			<button
				class="btn"
				type="button"
				:class="{ active: isFilterDropOpen === true }"
				@click="isFilterDropOpen = !isFilterDropOpen"
			>
				<i><img src="@/assets/images/vector/filter-btn.svg" alt="" /></i>
				<span>Sort By<em v-if="selectedSortField.query">: {{selectedSortField.name}}</em></span>
			</button>
			<div class="btn-drop" v-if="isFilterDropOpen" v-move-outside="handleClickSortOutside">
				<div class="drop-holder">
					<button
						class="drop-btn"
						@click="handleClickResetSorting"
						:class="{ active: !selectedSortField.query }"
						type="button"
					>
						<span>Default</span>
					</button>
					<template v-for="(sortField, i) of availableSortFields" :key="i">
						<button
							@click="handleClickSortBtn($event, sortField)"
							class="drop-btn"
							:class="{ active: sortField.name === selectedSortField.name }"
							type="button"
						>
							<span>{{ sortField.name }}</span>
						</button>
					</template>
				</div>
			</div>
		</div>

	</div>

	<div class="figures-list" :class="'itemSize-' + userFontSize">
		<template v-if="!isLoading">
			<figure-item v-for="(figureItem, i) of figuresResultsData" :key="i" :figure-data="figureItem" @open-gallery="handleClickPicture(i)" :index="i" />
		</template>
		<template v-else>
			<div class="figure-item" v-for="(item, i) of 24" :key="i">
				<div class="item-holder">
					<div class="image-box islazy">
						<div class="pic-wrap">
							<div class="pic">
								<div class="_g-lazy-block"></div>
							</div>
						</div>
						<div class="props-line">
							<div class="_g-lazy-block"></div>
						</div>
					</div>
					<div class="item-text _g-lazy-block"></div>
				</div>
			</div>
		</template>
	</div>
	<pagination />
</template>
