export default {
	mounted(el, binding) {
		el.clickOutsideEvent = function(event) {
			// Check if the clicked element is neither the element
			// to which the directive is applied nor its child
			let containsTarget = true
			let clickedParent = event.target.closest('.jsCurrentParent')
			if (!(el === event.target || el.contains(event.target))) {
				if(clickedParent){
					containsTarget = clickedParent.contains(el)
				}
				else{
					containsTarget = false
				}

				if(!event.target.closest('.jsCurrentParent') || !containsTarget){
					binding.value(event);
				}

			}
		};
		document.addEventListener('click', el.clickOutsideEvent);
	},
	unmounted(el) {
		// Remove the event listener when the bound element is unmounted
		document.removeEventListener('click', el.clickOutsideEvent);
	},
};
