import { createWebHistory, createRouter } from 'vue-router'
import DefaultLayout from "@/layout/DefaultLayout.vue"
import FrontPageLayout from "@/layout/FrontPageLayout.vue"
import FrontPage from '@/views/FrontPage/FrontPage.vue'
import SearchPage from '@/views/Search/SearchPage.vue'
import ErrorPage from '@/views/404/ErrorPage.vue'

const routes = [
	{
		path: '/',
		name: 'FrontPage',
		component: FrontPage,
		meta: {layout: FrontPageLayout},
	},
	{
		path: '/search/:s',
		name: 'SearchPage',
		props:true,
		component: SearchPage,
		meta: {layout: DefaultLayout},

	},

	// {
	// 	path: "/:pathMatch(.*)*",
	// 	name: "NotFound",
	// 	component: ErrorPage,
	// 	meta: {layout: DefaultLayout},
	// },
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		// return { top: 0 }
	}
})

export default router
