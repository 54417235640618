import axios from 'axios'

let API_BASE_URL = import.meta.env.VITE_API_SERVER
let ARCELLA_SERVER_URL = import.meta.env.VITE_ARCELLA_SERVER
if(import.meta.env.PROD){
	API_BASE_URL = import.meta.env.VITE_API_SERVER_PROD
	ARCELLA_SERVER_URL = import.meta.env.VITE_ARCELLA_SERVER_PROD
}
console.log(import.meta.env.DEV)

console.log(import.meta.env)
const defaultOptions = {
	baseURL: API_BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	},
	withCredentials: true
}

//календарь обновлять тоже при изменении сортировки
export const request = axios.create(defaultOptions)
//test  -  823d73672a7e18986e9638f145314186
//skahtml  -  89b052a361687acf017b59bcca5bd18d
const apiKey = '823d73672a7e18986e9638f145314186'
export const api = {
	search: {
		//TEXT SEARCH
		searchText: (
			searchQuery,
			resultsCount,
			yearFrom,
			yearTo,
			isHistogramChanged,
			articlesType,
			textAvailability,
			resultsFrom,
			sortField
		) => {
			let searchString = `/additionalsearch/?key=${apiKey}&database=text&searchquery=${searchQuery}&results=${resultsCount}`
			if(isHistogramChanged) {
				if (yearFrom) searchString += '&datefrom=' + yearFrom
				if (yearTo) searchString += '&dateto=' + yearTo
			}
			if (articlesType.length > 0) {
				searchString += `&articletype=${articlesType.join(',')}`
			}
			if (textAvailability.length > 0) {
				searchString += `&textavail=${textAvailability}`
			}
			if (resultsFrom) {
				searchString += `&resultsfrom=${resultsFrom}`
			}
			if (sortField.query) {
				searchString += `&sortfield=${sortField.query}`
			}
			console.log(`https://arcella.bio${searchString} - searchText sended`)
			return request.get(searchString)
		},

		//FIGURE SEARCH
		searchFigure: (
			searchQuery,
			resultsCount,
			yearFrom,
			yearTo,
			isHistogramChanged,
			resultsFrom,
			sortField,
			articleType,
			typeOfFigure
		) => {
			let searchString = `/additionalsearch/?key=${apiKey}&database=figure&searchquery=${searchQuery}&results=${resultsCount}`
			if(isHistogramChanged) {
				if (yearFrom) searchString += '&datefrom=' + yearFrom
				if (yearTo) searchString += '&dateto=' + yearTo
			}

			if (resultsFrom) {
				searchString += `&resultsfrom=${resultsFrom}`
			}
			if (sortField.query) {
				searchString += `&sortfield=${sortField.query}`
			}
			let notAllchecked = false
			let typeFigureQueryString = []
			typeOfFigure.map((chk) => {
				if (chk.selected === true) {
					typeFigureQueryString.push(chk.name)
				} else {
					notAllchecked = true
				}
			})
			typeFigureQueryString = typeFigureQueryString.toString()
			if (typeFigureQueryString.length && notAllchecked === true) {
				searchString += `&typeoffigure=${typeFigureQueryString}`
			}
			console.log(`https://arcella.bio${searchString} - searchFigures sended`)
			return request.get(searchString)
		},
		getYears: (searchQuery) =>
			request.get(`/additionalsearch/?key=${apiKey}&database=text&searchquery=${searchQuery}&getdates`),
		searchSingleColumn: (pmid, fieldName) => {
			//console.log(`/additional?key=${apiKey}&pmid=${pmid}&getpaperfield&field=${fieldName}&characters=500`)
			console.log(`/additional?key=${apiKey}&pmid=${pmid}&getpaperfield&field=${fieldName}`)
			return request.get(`/additional?key=${apiKey}&pmid=${pmid}&getpaperfield&field=${fieldName}`)
		}
	},

	expanded: {
		getAbstract: (pmid) => request.get(`/additional?key=${apiKey}&pmid=${pmid}&getabstract`),
		getPublicationtypes: (pmid) => request.get(`/additional?key=${apiKey}&pmid=${pmid}&publicationtypes`),
		getJournal: (pmid) => request.get(`/additional?key=${apiKey}&pmid=${pmid}&getjournal`),
		getArticleFigures: (pmid) =>
			request.get(`/apisearch?key=${apiKey}&db=figures&field=pmid&query=${pmid}&start=0&results=20`),
		getSuggestions: (query) => request.get(`/additional?key=${apiKey}&suggest&word=${query}`)
	},
	advancedSimilarity: {
		searchByUplolad: (file) => {
			let url = `/advancedsimilarity?key=${apiKey}&results=50`
			let formData = new FormData()
			formData.append('datafile', file)
			const axiosRequest = axios.create({
				baseURL: API_BASE_URL,
				headers: {
					Accept: 'application/json',
					'Content-Type': `multipart/form-data;`
				},
				withCredentials: true
			})
			return axiosRequest.post(url, formData)
		},
		searchBySearchID: (searchID, resultsCount, resultsFrom, yearFrom, yearTo, isHistogramChanged, articlesType, sortField) => {
			let searchString = `/advancedsimilarity?key=${apiKey}&searchID=${searchID}`


			if (yearFrom) searchString += '&datefrom=' + yearFrom
			if (yearTo) searchString += '&dateto=' + yearTo
			if (articlesType.length > 0) searchString += `&articletype=${articlesType.join(',')}`
			if (resultsFrom) {
				searchString += `&resultsfrom=${resultsFrom}&results=${resultsCount}`
			}
			if (sortField.query) searchString += `&sortfield=${sortField.query}`
			console.log(`arcella.bio${searchString} - searchBySearchID(upload) sended`)
			return request.get(searchString)
		},
		getDates: (searchID) => request.get(`/advancedsimilarity?key=${apiKey}&searchID=${searchID}&getdates`)
	},
	userservices:{
		googleAccInfo: (token) => {
			let url = `/userinfo?alt=json&access_token=${token}`
			const axiosRequest = axios.create({
				baseURL: "https://www.googleapis.com/oauth2/v3/userinfo",
				headers: {
					Authorization: `Bearer ${token}`
				}
			})


			return axiosRequest.get()
		},
		googleSignUp: (googleCredential) => {
			let url = `/userservices2`
			const axiosRequest = axios.create({
				baseURL: ARCELLA_SERVER_URL,
				headers: {
					Accept: 'application/json',
					'Content-Type': `application/json`
				},
				withCredentials: true
			})
			return axiosRequest.post(url, JSON.stringify({ token: googleCredential }))
		},
	}
}
